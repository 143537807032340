import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import './StyledLoading.scss'

const StyledLoading = () => {
  return (
    <div className='styled-loading'>
      <CircularProgress />
      <div className='loading-text'>
        Loading, please wait...
      </div>
    </div>
  )
}

export default StyledLoading