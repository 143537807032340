import React from "react"
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import './QrFeedbackSuccess.scss'

const QrFeedbackSuccess = () => {
  return (
    <div className="feedback-success">
      <div className="feedback-success-title">
        <CheckCircleOutlineIcon />
        Success!
      </div>
      <div className='feedback-success-text'>
        Your feedback has been sent. <br />
        Thank you for your contribution!
      </div>
    </div>
  )
}

export default QrFeedbackSuccess