import React from "react"
import './SecondaryButton.scss'
import classnames from 'classnames'

const SecondaryButton = ({ className, style, children, ...props }) => {

  const styling = {
    color: style?.color,
    backgroundColor: style?.accentColor,
    height: style?.height,
    width: style?.width
  }

  return (
    <button
      className={classnames('secondary-btn btn', className)}
      style={styling}
      {...props}
    >
      {children}
    </button>
  )
}

export default SecondaryButton