import React from "react"
import './NavLinkBtn.scss'
import { NavLink } from "react-router-dom"
import classnames from 'classnames'

const NavLinkBtn = ({ linkName, className, children, ...props }) => {
  return (
    <NavLink
      className={classnames('nav-link btn', className)}
      {...props}
    >
      <div className="nav-link-name">{children}</div>
    </NavLink>
  )
}

export default NavLinkBtn