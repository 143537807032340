import React from "react"
import './SimpleCard.scss'
import classnames from 'classnames'

const SimpleCard = ({ children, className, ...props }) => {
  return (
    <div className={
      classnames('simple-card-container', className, { 'interactive': props.onClick })}
      {...props}
    >
      {children}
    </div>
  )
}

export default SimpleCard