export const gymOlympiaGeneral = {
  "id": 1,
  "name": 'gym! general',
  "location": 1,
  "schema": {
    "style": {
      "backgroundColor": '#212121',
      "color": "#F3EFF5",
      "accentColor": "#ffd500",
    },
    "pages": [
      {
        "content": [
          {
            "id": 1,
            "type": "image",
            "image": '/GYM-Logo.png'
          },
          {
            "id": 4,
            "type": "email",
            "required": false,
            "label": "Your email address:",

          },
          {
            "id": 6,
            "type": "comment",
            "required": true,
            "label": "How was your visit to Gym! today? ",
          },
          {
            "id": 7,
            "type": "rating",
            "required": true,
            "label": "Rate us: ",
            "max": 10,
          },
          {
            "id": 3,
            "type": "text",
            "label": "* - mandatory fields",
          },
          {
            "id": 8,
            "type": "navigation",
            "label": "Send"
          }
        ]
      }
    ]
  }
}