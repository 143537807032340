import React from "react"
import './ImageViewer.scss'
import ImageIcon from '@mui/icons-material/BrokenImageOutlined'

const ImageViewer = ({ image, style, ...props }) => {

  const noImage = image === "" || !image

  return (
    <div className="image-viewer">
      {noImage ?
        <div className="no-image"><ImageIcon /></div>
        :
        <img style={style} src={image || ""} alt='uploaded-pic' />
      }
    </div>
  )
}

export default ImageViewer