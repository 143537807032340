import React from "react"
import PrimaryButton from "../../components/buttons/PrimaryButton"
import SecondaryButton from "../../components/buttons/SecondaryButton"

const PagesNavigation = ({ currentNavBtn, currentPage, pagesNo, pages, changePage, submitForm, disabled, overallStyle, ...props }) => {
  //const submitName = pages[pages.length - 1].content.find(el => el.type === 'navigation').label
  const currentNavStyle = currentNavBtn.style
  const currentNavLabel = currentNavBtn.label

  return (
    <div className="page-nagivation">
      {currentPage > 0 && <SecondaryButton
        fieldStyle={currentNavStyle}
        overallStyle={overallStyle}
        onClick={() => changePage(-1)}
        type='button'
        btnName='Previous'
      />}
      {(currentPage >= 0 && currentPage < pagesNo - 1) && <PrimaryButton
        onClick={() => changePage(1)}
        fieldStyle={currentNavStyle}
        overallStyle={overallStyle}
        type='button'
        disabled={disabled}
        btnName='Next'
      />}
      {(currentPage === pagesNo - 1) && <PrimaryButton
        fieldStyle={currentNavStyle}
        overallStyle={overallStyle}
        disabled={disabled}
        onClick={submitForm}
      >{currentNavLabel}
      </PrimaryButton>}
    </div>
  )
}

export default PagesNavigation