import React, { useState } from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import './StyledSnackbar.scss'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const StyledSnackbar = ({ isOpen, severity, children, ...props }) => {
  const [open, setOpen] = useState(isOpen)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false)
  }

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
      >
        {children}
      </Alert>
    </Snackbar>
  )
}

export default StyledSnackbar