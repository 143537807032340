import React, { useState } from "react"
import PageParser from "./PagesParser"
import PagesNavigation from "./PagesNavigation"
import StyledProgressBar from "../../components/progress-bar/StyledProgressBar"
import './QuizParser.scss'
import QrFeedbackSuccess from "./QrFeedbackSuccess"

const QuizParser = ({ quiz, isSubmitted, sendUserData, isDisabledNav, ...props }) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [userData, setUserData] = useState([])
  const [requiredField, setRequiredField] = useState([])
  const parsedQuiz = quiz?.schema
  const pages = parsedQuiz?.pages
  const pagesNo = pages?.length
  const pageStyle = parsedQuiz?.style

  const changePage = (i) => {
    const pageChange = currentPage + i
    if ((pageChange >= 0) && (pageChange < pagesNo)) {
      setCurrentPage(pageChange)
    }
  }

  const onSubmit = () => {
    sendUserData(userData)
  }

  const addAnswer = (value, fieldType) => {
    const ans = {}
    ans[fieldType] = value
    const currentAnswer = Object.assign({}, userData, ans)
    setUserData(currentAnswer)
  }

  if (typeof (pages) === 'undefined' || pages.length === 0 || pages[currentPage].content.length === 0) {
    return (
      <div className="no-container">
        No content was found!
      </div>
    )
  }

  return (
    <div style={pageStyle} className="page-parser-container">
      {!isSubmitted ?
        <div className="page-parser" >
          {pagesNo > 1 && <StyledProgressBar
            current={currentPage + 1}
            maxLimit={pagesNo}
          />}
          <PageParser
            isDisabledNav={isDisabledNav}
            currentPage={currentPage}
            pagesNo={pagesNo}
            overallStyle={pageStyle}
            page={pages[currentPage].content}
            userData={userData}
            onSubmit={onSubmit}
            requiredField={requiredField}
            setRequiredField={setRequiredField}
            setUserData={addAnswer}
          />
        </div>
        :
        <QrFeedbackSuccess />
      }
    </div>
  )
}

export default QuizParser