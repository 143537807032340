import React, { useState } from "react"
import './Login.scss'
import StyledInput from "../../components/inputs/StyledInput"
import PrimaryButton from "../../components/buttons/PrimaryButton"
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOffOutlined'
import { connect } from 'react-redux'
import { setUser } from '../../redux/ducks/user'
import { useNavigate } from "react-router-dom"
import StyledCheckbox from "../../components/checkbox/StyledCheckbox"
import TextLink from "../../components/buttons/TextLink"
import StyledInputIcon from "../../components/inputs/StyledInputIcon"

const mapStateToProps = (state, ownProps) => ({
  user: state.user
})

const mapDispatchToProps = {
  setUser
}

const connectToStore = connect(
  mapStateToProps,
  mapDispatchToProps
)

const Login = ({ user, setUser, ...props }) => {
  const [showPass, setShowPass] = useState(false)
  const [userInfo, setUserInfo] = useState({ email: '', pass: '', rememberMe: false })
  const navigate = useNavigate()

  const onSubmit = () => {
    console.log(userInfo)
    setUser({ ...user, isLoggedIn: true })
    navigate('/')
  }

  const enabled = userInfo.pass.length > 1 && userInfo.email.length > 1

  return (
    <div className="login-container">
      <div className="login">
        <div className="login-top">
          <div className='login-title'>Login</div>
          <div className='login-line'></div>
        </div>
        <form onSubmit={onSubmit}>
          <div className="login-fields">
            <StyledInput
              label='Email'
              value={userInfo.email}
              onChange={e => setUserInfo({ ...userInfo, email: e.target.value })}
              type='email'
              className='login-text-username'
              placeholder='Email...'
              required
            />
            <StyledInputIcon
              label='Password'
              required
              value={userInfo.pass}
              onChange={e => setUserInfo({ ...userInfo, pass: e.target.value })}
              placeholder='Password...'
              type={showPass ? 'text' : 'password'}
              icon={showPass ? <VisibilityOffIcon /> : <VisibilityIcon />}
              onClick={() => setShowPass(!showPass)}
              classname='login-text-password'
            />
            <StyledCheckbox
              label='Remember me'
              checked={userInfo.rememberMe}
              onChange={(e) => setUserInfo({ ...userInfo, rememberMe: e.target.checked })}
            />
            <div className="forgot-pass-field">
              <TextLink
                to='/reset-password/'
                className='login-nav-links'
                linkName='Forgot your password?'
              />
            </div>
            <div className="login-btn">
              <PrimaryButton style={{ width: 140, height: 45 }} disabled={!enabled}>
                Login
              </PrimaryButton>
            </div>

          </div>
        </form>
        <div className="login-create-acc">
          <div className='login-new-title'>New to Maoni?</div>
          <TextLink
            to='/register/'
            className='login-nav-links'
          >
            Create an account!
          </TextLink>
        </div>
      </div>
    </div >
  )
}

export default connectToStore(Login)