import './App.scss'
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom"
import Home from './pages/home/Home'
import NavBar from './components/navbar/NavBar'
import Login from './pages/login/Login'
import { connect } from 'react-redux'
import RegisterContainer from './pages/register/RegisterContainer'
import Logout from './pages/Logout'
import Profile from './pages/profile/Profile'
import Stats from './pages/stats/Stats'
import Settings from './pages/settings/Settings'
// import QREditor from './pages/qr-editor/qr-editor-setup/QREditor'
import QrFeedback from './pages/qr-viewer/QrFeedback'
import Sidebar from './components/navbar/Sidebar'
import Companies from './pages/companies/Companies'
import Locations from './pages/locations/Locations'
import CreatorPage from './pages/qr-editor/qr-editor-setup/CreatorPage'
import CreatorSetup from './pages/qr-editor/qr-editor-setup/CreatorSetup'
import { isDevelopmentEnvironment } from './utils/environment'
import Storybook from './pages/storybook/Storybook'

const mapStateToProps = (state, ownProps) => ({
  user: state.user
})

const connectToStore = connect(
  mapStateToProps,
  null
)

const App = ({ user, ...props }) => {
  return (
    <div className="App">
      {/* {isDevelopmentEnvironment && <NavBar />} */}
      <div className='app-page-container'>
        {isDevelopmentEnvironment && user.user.isLoggedIn && <Sidebar />}
        <Routes>
          {isDevelopmentEnvironment &&
            <>
              <Route path='/' element={<Home />} />
              <Route path='/storybook/' element={<Storybook />} />
              {!user.user.isLoggedIn && <Route path='/login/' element={<Login />} />}
              {user.user.isLoggedIn && <Route path='/logout/' element={<Logout />} />}
              {user.user.isLoggedIn && <Route path='/profile/' element={<Profile />} />}
              {user.user.isLoggedIn && <Route path='/settings/' element={<Settings />} />}
              {user.user.isLoggedIn && <Route path='/creator/' element={<CreatorPage />} />}
              {user.user.isLoggedIn && <Route path='/creator/setup/:id/' element={<CreatorSetup />} />}
              {user.user.isLoggedIn && <Route path='/stats/' element={<Stats />} />}
              {user.user.isLoggedIn && <Route path='/companies/' element={<Companies />} />}
              {user.user.isLoggedIn && <Route path='/locations/' element={<Locations />} />}
              {!user.user.isLoggedIn && <Route path='/register/' element={<RegisterContainer />} />}
              <Route path='*' element={<Navigate to="/" />} />
            </>}
          <Route exact path='/qr-feedback' element={<QrFeedback />} />
        </Routes>
      </div>
    </div>
  )
}

const Root = ({ user, ...props }) => {
  return (
    <BrowserRouter>
      <App user={user} />
    </BrowserRouter>
  )
}

export default connectToStore(Root)


