import React from "react"
import './StyledInput.scss'
import classnames from 'classnames'
import IconButton from "../buttons/IconButton"

const StyledInputIcon = ({ label, icon, onClick, classname, required, ...props }) => {
  return (
    <div className={classnames('styled-input styled-input-icon', classname)}>
      <div className="styled-input-label">{label}{required ? ' * ' : ''}</div>
      <div className="styled-input-input">
        <input
          required={required}
          {...props}
        />
        <IconButton
          onClick={onClick}
          className="input-icon"
        >
          {icon}
        </IconButton>

      </div>
    </div>
  )
}

export default StyledInputIcon