import React from "react"
import NavLinkBtn from "../buttons/NavLinkBtn"

const UnauthNavbar = ({ isMenuExtended, setIsMenuExtended, ...props }) => {
  return (
    <>
      <NavLinkBtn
        to="/login/"
        onClick={() => { setIsMenuExtended(!isMenuExtended) }}
      >
        Login
      </NavLinkBtn>
      <NavLinkBtn
        to="/register/"
        onClick={() => { setIsMenuExtended(!isMenuExtended) }}
      >
        Register
      </NavLinkBtn>
    </>
  )
}

export default UnauthNavbar