import React from 'react'
import Rating from '@mui/material/Rating'
import './StyledRating.scss'
import classnames from 'classnames'

const StyledRating = ({ label, required, fieldStyle, overallStyle, className, ...props }) => {

  const labelStyle = {
    color: fieldStyle?.labelColor || overallStyle?.color,
    fontSize: fieldStyle?.fontSize
  }

  const ratingStyle = {
    borderColor: fieldStyle?.starsColor || overallStyle?.accentColor,
  }

  return (
    <div className={classnames('styled-rating', className)}>
      <div style={labelStyle} className='styled-rating-label'>
        {label}{required ? ' * ' : ''}
      </div>
      <Rating
        sx={{
          '& .MuiRating-iconFilled': {
            color: ratingStyle.borderColor,
          },
          '& .MuiRating-iconEmpty': {
            color: ratingStyle.borderColor,
          },
        }}
        {...props}
      />
    </div>
  )
}

export default StyledRating