import React, { useEffect, useRef, useState } from "react"
import './NavBar.scss'
import { connect } from 'react-redux'
import NavLinkBtn from "../buttons/NavLinkBtn"
import { ReactComponent as Logo } from '../../pics/icons/logo.svg'
import { useLocation } from "react-router-dom"
import UnauthNavbar from "./UnauthNavbar"
import AuthNavbar from "./AuthNavbar"
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'

const mapStateToProps = (state, ownProps) => ({
  user: state.user
})

const connectToStore = connect(
  mapStateToProps,
  null
)

const NavBar = ({ user, ...props }) => {
  const location = useLocation().pathname
  const loggedIn = user.user.isLoggedIn
  const [isMenuExtended, setIsMenuExtended] = useState(false)
  const ref = useRef(null)
  const noRoutes = ['qr-feedback', 'storybook', 'login', 'register']

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        isMenuExtended && setIsMenuExtended(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [ref, isMenuExtended])

  if (noRoutes.find(loc => location.includes(loc))) return null

  return (
    <div className="navbar-container">
      <div className="navbar">
        <div className="navbar-logo">
          <NavLinkBtn
            className='logo'
            to='/'
          >
            <Logo />
            <div className='logo-text'>maoni</div>
          </NavLinkBtn>
        </div>
        <div className="logo-text-accent">DASHBOARD</div>
        <div className={`navbar-burger ${isMenuExtended ? 'extended-burger' : 'unextended-burger'}`}>
          <button onClick={() => { setIsMenuExtended(!isMenuExtended) }}>
            {isMenuExtended ? <CloseIcon /> : <MenuIcon />}
          </button>
        </div>
        <div ref={ref} className={`navbar-buttons ${isMenuExtended ? 'extended-links' : 'unextended-links'}`}>
          {loggedIn ?
            <AuthNavbar
              isMenuExtended={isMenuExtended}
              setIsMenuExtended={setIsMenuExtended}
            />
            :
            <UnauthNavbar
              isMenuExtended={isMenuExtended}
              setIsMenuExtended={setIsMenuExtended}
            />
          }
        </div>
      </div>
    </div>
  )
}

export default connectToStore(NavBar)