export const mainEestiCodes = [
  'tartu-veeriku',
  'tallinn-tehnopol',
  'tallinn-vanalinn',
  'tallinn-ulemiste',
  'tallinn-mustika',
  'tartu-tasku',
  'tallinn-oismae'
]

export const mainLatvijaCodes = [
  'gym-origo',
  'gym-imanta',
  'gym-olimpia'
]