import React, { useEffect, useState } from "react"
import QuizParser from "./QuizParser"
import { fitness1vestiar, fitness1reception, fitness1gym } from '../../mocks/temp/nr1fitness'
import { postQrSurvey, getQrSurvey } from "../../api/qrCompleteService"
import { useLocation } from "react-router-dom"
import StyledLoading from "../../components/loading/StyledLoading"
import './QrFeedback.scss'
import ErrorIcon from '@mui/icons-material/ErrorOutline'
import StyledSnackbar from "../../components/snackbar/StyledSnackbar"
import { insideGymCodes, receptionCodes, menChangingRoomCodes, womenChangingRoomCodes } from "../../mocks/temp/nr1fitnessHashcodes"
import { gymOlympiaGeneral } from "../../mocks/temp/gymOlympia"
import { mainEestiCodes, mainLatvijaCodes } from "../../mocks/temp/gymOlympiaHashcodes"

const QrFeedback = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [isDisabledNav, setIsDisabledNav] = useState(false)
  const [isWrongQrCode, setIsWrongQrCode] = useState(false)
  const [hasFailedSending, setHasFailedSending] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const location = useLocation().search
  const hash = new URLSearchParams(location).get('hash')


  // const quiz = insideGymCodes.includes(hash) && fitness1gym ||
  //   receptionCodes.includes(hash) && fitness1reception ||
  //   menChangingRoomCodes.includes(hash) && fitness1vestiar ||
  //   womenChangingRoomCodes.includes(hash) && fitness1vestiar

  const quiz = mainEestiCodes.includes(hash) && gymOlympiaGeneral

  // const quiz = mainLatvijaCodes.includes(hash) && gymOlympiaGeneral

  useEffect(() => {
    getSurvey()
  }, [hash])

  const getSurvey = () => {
    getQrSurvey(hash)
      .then(res => {
      })
      .catch(err => {
        setIsWrongQrCode(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const sendUserData = (userData) => {
    setIsDisabledNav(true)
    setHasFailedSending(false)
    postQrSurvey(hash, userData)
      .then(res => {
        setIsSubmitted(true)
      })
      .catch(err => {
        setHasFailedSending(true)
      })
      .finally(() => {
        setIsDisabledNav(false)
      })
  }

  if (isLoading) return <StyledLoading />

  if (isWrongQrCode) return <ErrorLoadingCode />

  return (
    <>
      <QuizParser
        quiz={quiz}
        isDisabledNav={isDisabledNav}
        isSubmitted={isSubmitted}
        sendUserData={sendUserData}
      />
      {hasFailedSending &&
        <StyledSnackbar isOpen={hasFailedSending} severity='error'>
          There was an issue when sending the data, please try again!
        </StyledSnackbar>
      }
    </>
  )
}

export default QrFeedback

const ErrorLoadingCode = () => {
  return (
    <div className="qr-code-wrong">
      <ErrorIcon />
      There was an issue when scanning the code.<br />
      Please try again later.
    </div>
  )
}