import React from "react"
import './StyledProgressBar.scss'
import LinearProgress from '@mui/material/LinearProgress'

const StyledProgressBar = ({ current, maxLimit, ...props }) => {

  const percentCurrent = (current - 1) * 100 / maxLimit
  return (
    <div className="styled-progress-bar">
      <div className="styled-progress-label">{`${current}/${maxLimit}`}</div>
      <LinearProgress variant="determinate" value={percentCurrent} />
    </div>
  )
}

export default StyledProgressBar