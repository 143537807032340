import React from "react"
import './Typography.scss'
import classnames from 'classnames'

const Typography = ({ variant, children, ...props }) => {

  return (
    <div
      className={classnames({
        [`typography typography-${variant}`]: variant
      })}
      {...props}
    >
      {children}
    </div>
  )
}

export default Typography