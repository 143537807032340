import axios from 'axios'
import { PROXY_ADDRESS } from './config'

const axs = axios.create({
  baseURL: PROXY_ADDRESS
})

axs.interceptors.request.use(
  config => {
    const token = window.localStorage.getItem('jwt_access_token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    } else {
      delete axs.defaults.headers.common.Authorization
    }
    return config
  },
  null
)

axs.interceptors.response.use(
  (res) => {
    // successfull response, nothing to do here
    return Promise.resolve(res)
  },
  (err) => {
    // don't know if there's a use case for now
    return Promise.reject(err)
  }
)

export const getQrSurvey = (slug) => {
  return axs.get(`/feedback/qrinfo/${slug}/`)
}

export const postQrSurvey = (slug, data) => {
  return axs.post(`/feedback/${slug}/`, data)
}

export default axs