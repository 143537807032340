import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import Root from './App'
import { Provider } from 'react-redux'
import store from './redux/store.js'
// import * as themes from './themes/schema.json'
// import { setToLS } from './utils/storage'

const root = ReactDOM.createRoot(document.getElementById('root'))
// setToLS('all-themes', themes.default) // add for theming

root.render(

  <Provider store={store}>
    <Root />
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
