import React from "react"
import './Home.scss'
import { connect } from 'react-redux'
import HomeAuthorized from "./HomeAuthorized"
import HomeUnauthorized from "./HomeUnauthorized"

const mapStateToProps = (state, ownProps) => ({
  user: state.user
})

const connectToStore = connect(
  mapStateToProps,
  null
)

const Home = ({ user, ...props }) => {
  return (
    user.user.isLoggedIn ?
      <HomeAuthorized />
      :
      <HomeUnauthorized />
  )
}

export default connectToStore(Home)