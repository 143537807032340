import { useEffect } from "react"
import { connect } from 'react-redux'
import { setUser } from '../redux/ducks/user'
import { useNavigate } from "react-router-dom"

const mapStateToProps = (state, ownProps) => ({
  user: state.user
})

const mapDispatchToProps = {
  setUser
}

const connectToStore = connect(
  mapStateToProps,
  mapDispatchToProps
)

const Logout = ({ user, setUser, ...props }) => {
  const navigate = useNavigate()

  useEffect(() => {
    setUser({ ...user, isLoggedIn: false })
    navigate('/')
  }, [])

  return (
    null
  )
}

export default connectToStore(Logout)