import React from "react"
import './PageContainer.scss'
import classnames from 'classnames'
import Footer from "../footer/Footer"

const PageContainer = ({ children, className, ...props }) => {
  return (
    <div className={classnames('page-container', className)}>
      {children}
    </div>
  )
}

export default PageContainer