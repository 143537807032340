import React from "react"
import { NavLink } from "react-router-dom"
import './HomeUnauthorized.scss'

const HomeUnauthorized = () => {
  return (
    <div className="home-unauthorized-container">
      Home but unauthorized page <br />
      Some news or something
      <div className="create-account">
        <NavLink to='/register/'>Create an account</NavLink><br />
        or <br />
        <NavLink to='/login/'>Login</NavLink>
      </div>

    </div>
  )
}

export default HomeUnauthorized