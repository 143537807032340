import React from "react"
import './TextLink.scss'
import { NavLink } from "react-router-dom"
import classnames from 'classnames'

const TextLink = ({ linkName, className, children, ...props }) => {
  return (
    <NavLink
      className={classnames('text-link btn', className)}
      {...props}
    >
      <div className="text-link-name">{children}</div>
    </NavLink>
  )
}

export default TextLink