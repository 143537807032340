import React from "react"
import PageContainer from "../../components/page-container/PageContainer"
import Typography from "../../components/typography/Typography"
import SimpleCard from "../../components/styled-cards/SimpleCard"
import './HomeAuthorized.scss'
import WavingHandIcon from '@mui/icons-material/WavingHandOutlined'

const HomeAuthorized = () => {

  return (
    <PageContainer className='home-authorized-container'>
      <SimpleCard className="home-welcome-section">
        <div className="welcome-title">
          <Typography variant='h1'>
            Welcome back!
          </Typography>
          <WavingHandIcon />
        </div>
        <div className="home-welcome-content">
          Something exciting here.
        </div>
        <div className="home-welcome-content">
          Maybe
        </div>
      </SimpleCard>
      <div className="home-companies-section">
        <Typography variant='subheading2'>
          Your companies
        </Typography>
        <div className="companies-cards">
          <SimpleCard style={{ width: 200, height: 75 }}>Company</SimpleCard>
        </div>
      </div>

      <div className="home-locations-section">
        <Typography variant='subheading2'>
          Your locations
        </Typography>
        <div className="locations-cards">
          <SimpleCard style={{ width: 200, height: 75 }}>Locations</SimpleCard>
          <SimpleCard style={{ width: 200, height: 75 }}>Locations</SimpleCard>
          <SimpleCard style={{ width: 200, height: 75 }}>Locations</SimpleCard>
        </div>
      </div>

      <div className="home-stats-section">
        <Typography variant='subheading2'>
          Quick stats
        </Typography>
        <div className="stats-cards">
          <SimpleCard style={{ width: 200, height: 200 }}>Stats</SimpleCard>
          <SimpleCard style={{ width: 200, height: 200 }}>Stats</SimpleCard>
          <SimpleCard style={{ width: 200, height: 200 }}>Stats</SimpleCard>
        </div>
      </div>

    </PageContainer>
  )
}

export default HomeAuthorized