import React from "react"
import NavLinkBtn from "../buttons/NavLinkBtn"
import AccountIcon from '@mui/icons-material/AccountCircleOutlined'
import StyledMenu from "../menu/StyledMenu"
import NotificationsIcon from '@mui/icons-material/NotificationsNone'

const AuthNavbar = ({ isMenuExtended, setIsMenuExtended, ...props }) => {

  return (
    <>
      <StyledMenu
        title='Notifications'
        btnName={<NotificationsIcon />}
      >
        <div>Notification</div>
        <div>Notification</div>
        <div>Notification</div>
      </StyledMenu>
      <StyledMenu
        btnName={<AccountIcon />}
        title='Account'
      >
        <NavLinkBtn to="/profile/">Profile</NavLinkBtn>
        <NavLinkBtn to="/settings/">Settings</NavLinkBtn>
        <NavLinkBtn to="/logout/" className='right'>Logout</NavLinkBtn>
      </StyledMenu>

    </>
  )
}

export default AuthNavbar