import React from "react"
import './StyledCheckbox.scss'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import classnames from 'classnames'

const StyledCheckbox = ({ label, className, ...props }) => {

  return (
    <div className={classnames('styled-checkbox', className)}>
      <FormControlLabel
        label={label}
        control={
          <Checkbox
            {...props}
          />}
      />
    </div>
  )
}

export default StyledCheckbox