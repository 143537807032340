import React, { useEffect, useRef, useState } from "react"
import './StyledMenu.scss'
import classnames from 'classnames'
import IconButton from "../buttons/IconButton"

const StyledMenu = ({ btnName, children, title, ...props }) => {
  const menuRef = useRef(null)
  const [isMenuExtended, setIsMenuExtended] = useState(false)

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        isMenuExtended && setIsMenuExtended(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [menuRef, isMenuExtended])

  return (
    <div ref={menuRef} className="styled-menu">
      <IconButton
        className={classnames('menu-button', { 'active': isMenuExtended })}
        onClick={() => setIsMenuExtended(!isMenuExtended)}
        borderType='round'
        title={title}
      >
        {btnName}
      </IconButton>
      {isMenuExtended &&
        <div className='styled-menu-items' >
          {children && children.map((el, i) => (
            <div
              key={`menu-item-${i}`}
              className='styled-menu-item'
              onClick={() => { setIsMenuExtended(!isMenuExtended) }}
            >
              {el}
            </div>
          ))}
        </div>}
    </div>
  )
}

export default StyledMenu