import React, { useEffect } from "react"
import FieldParser from "./FieldParser"
import PagesNavigation from "./PagesNavigation"

const PageParser = ({ onSubmit, isDisabledNav, changePage, requiredField, currentPage, pagesNo, page, userData, setUserData, setRequiredField, overallStyle, ...props }) => {

  useEffect(() => {
    let req = []
    page.forEach(el => {
      el.required && req.push(el.type)
    })
    setRequiredField(req)
  }, [page])

  const navEnabled = () => {
    let userDataId = Object.keys(userData)
    // only take into consideration answers of required field types
    let requiredAnswers = []
    userDataId.forEach((el, i) => {
      if (requiredField.includes(el)) {
        requiredAnswers.push(Object.values(userData).find(key => userData[el] === key))
      }
    })
    if (requiredField.every(r => userDataId.includes(r)) &&
      requiredAnswers.every(a => a.replace(/\s/g, '').length > 0)) {
      return true
    }
    return false
  }

  const currentNavBtn = page.find(el => el.type === 'navigation')

  return (
    <>
      {(page.length < 2) ?
        <div className="page">
          No content was found in the page.
        </div>
        :
        page.map((el, i) => (
          <FieldParser
            overallStyle={overallStyle}
            key={el.id + 'item'}
            field={el}
            id={el.id}
            userData={userData}
            setUserData={setUserData}
          />))
      }
      <PagesNavigation
        overallStyle={overallStyle}
        currentPage={currentPage}
        pagesNo={pagesNo}
        disabled={isDisabledNav || !navEnabled()}
        submitForm={onSubmit}
        currentNavBtn={currentNavBtn}
        changePage={changePage}
      />
    </>
  )
}


export default PageParser