import React from "react"
import './TextViewer.scss'

const TextViewer = ({ label, fieldStyle, overallStyle, ...props }) => {
  const style = {
    color: fieldStyle?.labelColor || overallStyle?.color,
    fontSize: fieldStyle?.fontSize,
    textAlign: fieldStyle?.textAlign
  }

  return (
    <div style={style} className="text-viewer">
      {label}
    </div>
  )
}

export default TextViewer