import React from "react"
import './StyledInput.scss'
import classnames from 'classnames'

const StyledInput = ({ label, type, className, fieldStyle, overallStyle, ...props }) => {

  const labelStyle = {
    color: fieldStyle?.labelColor || overallStyle?.color,
    fontSize: fieldStyle?.fontSize
  }

  const inputStyle = {
    color: fieldStyle?.labelColor || overallStyle?.color,
    borderColor: fieldStyle?.textboxColor || overallStyle?.accentColor,
  }

  return (
    <div className={classnames('styled-input', className)}>
      <div style={labelStyle} className="styled-input-label">
        {label}{props.required ? ' * ' : ''}
      </div>
      {type === 'textarea' ?
        <textarea
          style={inputStyle}
          {...props}
        />
        :
        <input
          style={inputStyle}
          type={type}
          {...props}
        />
      }
      {props?.maxLength &&
        <div style={{ color: labelStyle.color }} className="styled-input-limit">
          {`${props.value?.length || 0}/${props?.maxLength}`}
        </div>
      }
    </div>
  )
}

export default StyledInput